<template>
  <div>
    <v-autocomplete
      v-bind="$attrs"
      v-on="$listeners"
      :search-input.sync="filterSearch"
      loading="filterItemsLoading"
      :items="allSignalServers"
      :cache-items="true"
      @select="selectValue"
      v-model="val"
    >
      <template v-slot:append-item>
        <div
          v-if="lastPageOfgetItemsListComputed && !filterSearch"
          v-observe-visibility="{
            callback: visibilityChanged,
          }"
          class="selectPagination"
        >
          <span class="pa-2">Loading more items ...</span>
        </div>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
/**
 * Component of select with list of signal servers that are loaded async by chunks, with search
 */
import { mapGetters } from "vuex"
import _ from "lodash"

export default {
  name: "SelectSignalsAsync",

  inheritAttrs: false,

  props: {
    value: {
      type: [Number, String, Object],
    },
    customFilters: {
      type: Object,
    },
    relist: {
      type: Boolean,
      default: false,
    },
    prependEmptyOption: {
      type: Boolean,
      default: true,
    },
    prependCustomOptions: {
      type: Array,
      default: null,
    },
  },

  data: () => ({
    filterSearch: null,
    selectPagination: {
      p: 1,
      pp: 20,
    },
  }),

  mounted: function () {
    this.$store.dispatch("resetSignalServersList").then(() => {
      this.fetchItems()
    })
  },

  watch: {
    selected(val) {
      this.$emit("update:selected", val)
    },
    filterSearch() {
      this.selectPagination.p = 1
      this.fetchItems()
    },
    value: {
      handler: function (val, oldVal) {
        if (val && val !== oldVal) {
          this.getDataForSelected()
        }
      },
      immediate: true,
    },
    relist() {
      this.$store.dispatch("resetSignalServersList").then(() => {
        this.fetchItems()
      })
    },
  },

  methods: {
    visibilityChanged(e) {
      if (this.lastPageOfgetItemsListComputed) {
        e && this.nextPage()
      }
    },
    startPage() {
      this.selectPagination.p = 1
    },
    nextPage() {
      let maxPages = Math.ceil(this.itemsCount / this.selectPagination.pp)
      if (this.selectPagination.p <= maxPages - 1) {
        this.filterItemsLoading = true
        this.selectPagination.p += 1
        this.fetchItems()
      }
      this.filterItemsLoading = false
    },
    fetchItems() {
      let fetchData = {
        params: {
          p: this.selectPagination.p,
          pp: this.selectPagination.pp,
          keep: "_key,data",
          type: "signal_server",
        },
      }
      if (this.filterSearch) fetchData.params.search = this.filterSearch
      if (this.customFilters)
        fetchData.params = { ...fetchData.params, ...this.customFilters }
      this.$store.dispatch("signalServersList", fetchData)
    },
    selectValue() {
      this.filterSearch = ""
    },
    getDataForSelected() {
      this.$store.dispatch("signalSelectedInlist", {
        params: {
          _key: this.value,
          type: "signal_server",
        },
      })
    },
  },

  computed: {
    ...mapGetters({
      signalServersList: "signalServersList",
      itemsCount: "signalServersListCount",
    }),
    lastPageOfgetItemsListComputed() {
      let flag =
        this.itemsCount - this.selectPagination.p * this.selectPagination.pp > 0
      return flag
    },
    allSignalServers() {
      let list = this.signalServersList
      if (this.prependEmptyOption) {
        list = _.unionBy(
          list,
          [{ _key: null, data: { name: "Not selected" } }],
          "_key"
        )
      }
      if (this.prependCustomOptions) {
        list = _.unionBy(list, this.prependCustomOptions, "_key")
      }
      return list
    },
    val: {
      get() {
        return this.value
      },
      set() {},
    },
  },
}
</script>
