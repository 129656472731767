<template>
  <div>
    <v-card data-testid="container-debug-turn-servers">
      <v-toolbar>
        <v-card-title>Debug Info Turn servers</v-card-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-card-text>
        <DataTableExtended
          ref="refTableTurns"
          :headers="headers"
          :items="debugTurnServersList"
          sortable
          class="elevation-0 border pt-3"
          :loading="showTableLoader"
          :no-data-text="showTableLoader ? 'Loading…' : 'No data available'"
          :server-items-length="debugTurnServersListCount"
          v-on:init-table-data="getDebugTurnsInfo"
          @update-search="
            (newS) => {
              this.filterSearch = newS
            }
          "
          data-testid="table-debug-turn-servers"
        >
          <template v-slot:top>
            <v-row class="px-3">
              <v-col cols="12" md="3">
                <SelectSignalsAsync
                  v-model="selectedSignal"
                  outlined
                  label="Result from signal (s)"
                  :multiple="false"
                  item-text="data.name"
                  item-value="_key"
                  :error-messages="errors.collect(`form-signal-servers.backup`)"
                  data-vv-name="backup"
                  data-vv-validate-on="change"
                  data-vv-as="Backup"
                  field="backup"
                  :menu-props="{ maxHeight: 304 }"
                  :prependEmptyOption="false"
                  @change="getDebugTurnsInfo()"
                  return-object
                  data-testid="filter-signal"
                />
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="filterSearch"
                  outlined
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  :style="$vuetify.breakpoint.smAndUp ? 'max-width: 300px' : ''"
                  clearable
                  data-testid="filter-search"
                />
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.data.name`]="{ item }">
            <span data-testid="name">
              {{ item.data.name }}
            </span>
          </template>
          <template v-slot:[`item.data.url`]="{ item }">
            <span data-testid="url">
              {{ item.data.url }}
            </span>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <div
              v-if="typeof item.status === 'undefined'"
              class="primary--text"
            >
              <span class="overline">
                <v-progress-circular
                  :size="16"
                  indeterminate
                  color="primary"
                  class="mx-2"
                  data-testid="progress-loading"
                />
              </span>
              <span class="font-weight-regular">Loading</span>
            </div>

            <div
              v-else-if="turnIsUnknown(item)"
              class="opaced"
              data-testid="status"
            >
              <span class="overline">
                <i class="fa fa-circle mx-2"></i>
              </span>
              <span class="font-weight-regular">Unknown</span>
            </div>

            <div
              v-else
              :class="
                turnIsActive(item)
                  ? Pallette.statusText.active
                  : Pallette.statusText.notActive
              "
              data-testid="status"
            >
              <span class="overline" data-testid="status-icon">
                <i class="fa fa-circle mx-2"></i>
              </span>
              <span class="font-weight-regular" data-testid="status-text">{{
                turnIsActive(item) ? "Active" : "Inactive"
              }}</span>
            </div>
          </template>
        </DataTableExtended>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import DataTableExtended from "@/components/table/DataTableExtended.vue"
import SelectSignalsAsync from "@/components/selects/SelectSignalsAsync"
import {
  SignalServerService,
  STATUS_SERVER_ACTIVE,
  STATUS_SERVER_UNKNOWN,
} from "@/common/signal.service"

export default {
  components: {
    DataTableExtended,
    SelectSignalsAsync,
  },

  data() {
    return {
      headers: [
        {
          text: "Name",
          align: "left",
          value: "data.name",
          width: 200,
          sortable: true,
        },
        {
          text: "Status",
          align: "center",
          value: "status",
          width: 130,
          sortable: true,
        },
        {
          text: "Url",
          align: "left",
          value: "data.url",
          sortable: true,
        },
      ],
      selectedSignal: null,
      filterSearch: "",
    }
  },

  watch: {
    filterSearch() {
      this.getDebugTurnsInfo()
    },
  },

  mounted: function () {
    let self = this
    self.getSignalServersList().then(() => {
      self.setInitialSignal().then(() => {
        self.getDebugTurnsInfo()
      })
    })
  },

  methods: {
    getDebugTurnsInfo() {
      let self = this
      let getParams = self.$refs.refTableTurns.getTableServerParams()
      if (self.filterSearch !== "") getParams.search = self.filterSearch
      let fetchData = {
        params: getParams,
      }
      self.$store.dispatch("debugTurnServersList", fetchData).then(() => {
        self.getApiTurnsDebug().then(() => {
          self.$store.dispatch("parseTurnsApiDebugInfo")
        })
      })
    },
    getSignalServersList: function () {
      return this.$store.dispatch("debugSignalServersList", {
        params: { params: { p: 1, pp: 500 } },
      })
    },
    // get info from all signals about turns debug
    getApiTurnsDebug() {
      let self = this
      return new Promise((resolve) => {
        self.$store.dispatch("resetTurnsApiDebugInfo").then(() => {
          let prms = []
          self.signalsFetch.forEach((signal) => {
            prms.push(self.oneSignalTurnsApi(signal))
          })
          Promise.all(prms).then(() => {
            resolve()
          })
        })
      })
    },
    // get data from one signal about turns debug
    oneSignalTurnsApi(signal) {
      let self = this
      return new Promise((resolve) => {
        SignalServerService.turnsStatuses(signal.data.url)
          .then((res) => {
            self.$store
              .dispatch("saveTurnsApiDebugInfo", res?.data?.data)
              .then(() => {
                resolve()
              })
          })
          .catch(({ response }) => {
            resolve()
            // console.error('Error--getTurnsAliveStatuses', response)
          })
      })
    },
    turnIsActive(item) {
      return item.status === STATUS_SERVER_ACTIVE
    },
    turnIsUnknown(item) {
      return item.status === STATUS_SERVER_UNKNOWN
    },
    setInitialSignal() {
      let self = this
      return new Promise((resolve) => {
        switch (window.location.hostname) {
          case "dev.arht.redox-dev.com":
            self.selectedSignal = self.getSignalByHost(
              "signal-dev.arhtengine.com"
            )
            resolve(self.getSignalByHost("signal-dev.arhtengine.com"))
            break
          case "stage-arht.redox-dev.com":
            self.selectedSignal = self.getSignalByHost(
              "signal-stage.arhtengine.com"
            )
            break
          case "app.arhtengine.com":
            self.selectedSignal = self.getSignalByHost(
              "signal-prod.arhtengine.com"
            )
            break
          default:
            self.selectedSignal = self.getSignalByHost(
              "signal-dev.arhtengine.com"
            )
        }
        resolve()
      })
    },
    getSignalByHost(signalHost) {
      return (
        this.debugSignalServersList.find(
          (s) => s.data.hostname === signalHost
        ) ||
        (this.debugSignalServersList.length > 0 &&
          this.debugSignalServersList[0])
      )
    },
  },

  computed: {
    ...mapGetters({
      showTableLoader: "getShowTableLoader",
      Pallette: "get_Pallette",
      debugTurnServersList: "debugTurnServersList",
      debugTurnServersListCount: "debugTurnServersListCount",
      debugSignalServersList: "debugSignalServersList",
      turnApiDebugInfo: "turnApiDebugInfo",
    }),

    signalsFetch() {
      let findedSignal = this.debugSignalServersList.find(
        (s) => s._key === this.selectedSignal?._key
      )
      return findedSignal ? [findedSignal] : []
    },
  },
}
</script>

<style lang="scss" scoped>
.opaced {
  opacity: 0.6;
}
</style>
